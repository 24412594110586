import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import useSetTitle from "@hooks/_General/useSetTitle";
import { useSnackbar } from "@hooks/SnackbarProvider";
import useInstallImagesInfo from "@hooks/InstPic/useInstallImagesInfo";
import InstallImagesDataGridPremium from "@pages/InstPic/InstallImages/components/InstallImagesDataGridPremium";
import LoadingIndicator from "@components/LoadingIndicator";

export default function InstallImages() {
  const dev = true;

  useSetTitle("Upcoming Install Picture LookUp");
  const vaiInstallImagesInfo = useInstallImagesInfo();

  const [customerNumber, setCustomerNumber] = useState("");

  const snackbar = useSnackbar();

  const handleSearch = (e) => {
    e.preventDefault();

    vaiInstallImagesInfo
      .fetchInstallImagesInfo({
        dev,
        customerNumber,
      })
      .then((result) => {
        console.log("Install images successfully loaded for: ", customerNumber);
      })
      .catch((err) => {
        console.log(customerNumber);
        console.log("Error in handleSearch: ", err);
      });
  };

  useEffect(() => {
    if (
      vaiInstallImagesInfo.installImagesInfoError == null &&
      vaiInstallImagesInfo.installImagesInfoResults &&
      vaiInstallImagesInfo.installImagesInfoResults.status === 200
    ) {
      snackbar("The Search for Install Images was a Success", "success");
    }

    if (vaiInstallImagesInfo.installImagesInfoError != null) {
      console.log(
        "This is the message:",
        vaiInstallImagesInfo.installImagesInfoError
      );
      if (
        vaiInstallImagesInfo.installImagesInfoError.toString() ===
        "Error: The customer number is too short"
      ) {
        snackbar(
          vaiInstallImagesInfo.installImagesInfoError.toString(),
          "error"
        );
      } else {
        snackbar(
          "There was an Error in the Install Images Search process",
          "error"
        );
      }
    }
  }, [
    vaiInstallImagesInfo.installImagesInfoError,
    vaiInstallImagesInfo.installImagesInfoResults,
  ]);

  const imagesInfoObject = vaiInstallImagesInfo.installImagesInfoResults
    ? vaiInstallImagesInfo.installImagesInfoResults.data.data
    : null;

  return (
    <Card>
      <CardHeader
        title="Upcoming Install Job Pictures Lookup"
        subheader="Find the Order Images for Customer Installs"
        action={<></>}
      />
      <CardContent key={1} component="form" onSubmit={handleSearch}>
        <Grid container spacing={1} justifyContent="left">
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <TextField
              fullWidth
              label="Search by Customer Number"
              variant="outlined"
              value={customerNumber}
              onChange={(e) => setCustomerNumber(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={1.5} textAlign="center">
            <Button variant="contained" color="primary" type="submit">
              Search
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent
        key={2}
        sx={{
          flex: 1, // Allow this section to grow and take up remaining space
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            minWidth: "100%",
            width: "100%",
          }}
        >
          {vaiInstallImagesInfo.installImagesInfoLoading ? (
            <LoadingIndicator
              styles={
                "absolute top-0 left-0 w-full h-full bg-gray-100 bg-opacity-90 z-top-custom flex justify-center items-center"
              }
            ></LoadingIndicator>
          ) : null}
          {imagesInfoObject &&
            Object.keys(imagesInfoObject).map((keyName, keyIndex) => {
              const typeFolderItem = imagesInfoObject[keyName];
              console.log("In Type Folder Item: ", keyName);
              return (
                <Box
                  key={keyName}
                  style={{
                    marginBottom: "32px",
                  }}
                >
                  <InstallImagesDataGridPremium
                    typeFolderInfoObject={typeFolderItem}
                    fetchInstallImage={vaiInstallImagesInfo.fetchInstallImage}
                    installImageLoading={
                      vaiInstallImagesInfo.installImageLoading
                    }
                    installImageError={vaiInstallImagesInfo.installImageError}
                    installImageResults={
                      vaiInstallImagesInfo.installImageResults
                    }
                    setInstallImageFilePath={
                      vaiInstallImagesInfo.setInstallImageFilePath
                    }
                  ></InstallImagesDataGridPremium>
                </Box>
              );
            })}
        </Box>
      </CardContent>
    </Card>
  );
}
