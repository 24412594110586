import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../SnackbarProvider";
import axios from "axios";
import routes from "../../routes/routes";

// Constants
const DEFAULT_AUTH_URL = routes?.default?.direct || "/";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
const TOKEN_STORAGE_KEY = "token";
const USER_STORAGE_KEY = "userAuthData";
const FAILED_MSG = "Invalid username or password.";
const FIELD_TOO_LONG_MSG = `${FAILED_MSG} Field cannot be more than 50 characters.`;
const FIELD_TOO_SHORT_MSG = `${FAILED_MSG} Field cannot be less than 3 characters.`;
const FIELD_EMPTY_MSG = `${FAILED_MSG} Field cannot be empty.`;
const HTTP_STATUS_OK = 200;
const HTTP_STATUS_NO_CONTENT = 204;
const HTTP_STATUS_BAD_REQUEST = 400;
const HTTP_STATUS_INTERNAL_ERROR = 500;

/**
 * Validates the username and password input.
 * @param {{ username: string, password: string }} params - The username and password to validate.
 * @returns {string|true} - True if valid, or an error message string if invalid.
 */
const validateLogin = ({ username = "", password = "" } = {}) => {
  if (!username.trim() || !password.trim()) {
    return FIELD_EMPTY_MSG;
  }
  if (username.length > 50 || password.length > 150) {
    return FIELD_TOO_LONG_MSG;
  }
  if (username.length < 3 || password.length < 3) {
    return FIELD_TOO_SHORT_MSG;
  }
  return true;
};

// Custom hook to handle authentication
const useAuthenticationHook = () => {
  // Hooks
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  // States for error and user data
  const [error, setError] = useState(null);
  const [token, setToken] = useState(() => {
    // Attempt to get token from local storage on initial load
    const savedToken = localStorage.getItem(TOKEN_STORAGE_KEY);
    return savedToken || null;
  });
  const [userData, setUserData] = useState(() => {
    // Attempt to get userData from local storage on initial load
    const savedUserData = localStorage.getItem(USER_STORAGE_KEY);
    return savedUserData ? JSON.parse(savedUserData) : null;
  });
  const [authenticated, setAuthenticated] = useState(userData && token);

  // Axios instance for authentication only
  const [query, setQuery] = useState(() => {
    const instance = axios.create({
      baseURL: BASE_URL,
    });
    if (token) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      // Add the accept header for JSON
      instance.defaults.headers.common["Accept"] = "application/json";
    }
    return instance;
  });

  // Function to save user data to local storage
  const saveUserData = (data, sendToURL) => {
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(data.user));
    localStorage.setItem(TOKEN_STORAGE_KEY, data.token);
    setUserData(data.user);
    setToken(data.token);
    setAuthenticated(true);

    // Set token to axios instance
    query.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;

    // Redirect to default URL
    navigate(sendToURL);
  };

  // Function to clear user data from local storage
  const clearUserData = () => {
    localStorage.removeItem(USER_STORAGE_KEY);
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    setUserData(null);
    setToken(null);
    setAuthenticated(false);
    navigate(LOGIN_URL);
  };

  // Login function
  const login = useCallback(async ({ username, password } = {}) => {
    const validationMessage = validateLogin({ username, password });
    if (validationMessage !== true) {
      setError({
        status: HTTP_STATUS_BAD_REQUEST,
        message: validationMessage,
      });
      clearUserData();
      return;
    }

    try {
      const response = await query.post("/ldap-login", {
        username,
        password,
      });
      const { data = {}, status = HTTP_STATUS_INTERNAL_ERROR } = response;

      if (
        status !== HTTP_STATUS_OK ||
        !data.token ||
        Object.keys(data.user).length === 0
      ) {
        const message = _.get(data, "message", FAILED_MSG);
        console.error("Failed to login", message, status);
        setError({ status, message });
        clearUserData();
      } else {
        // Calculate the home url for the user using the groups in the routes object
        const adminGroup = _.get(routes, "default.adminGroup", "");
        const userGroups = _.get(data, "groups", []);
        const userIsAdmin = userGroups.includes(adminGroup);

        const filteredRoutes = Object.entries(routes).filter(
          ([key, { groups: pathGroups = [] }]) => {
            if (key === "default") return false;

            // If the user is an admin, they have access to all routes
            if (userIsAdmin) return true;

            // If there is no groups key for the route, check for children with groups
            const groupKeyExists = _.get(routes, `${key}.groups`, false);

            // If the group key is null, the route doesn't require any groups
            if (groupKeyExists === null) return true;

            if (!groupKeyExists) {
              const children = _.get(routes, `${key}.children`, []);
              return children.some((child) => {
                const childGroups = _.get(child, "groups", []);
                return userGroups.some((group) => childGroups.includes(group));
              });
            } else {
              return pathGroups.some((group) => userGroups.includes(group));
            }
          }
        );

        if (filteredRoutes.length === 0) {
          console.error("User does not have access to any routes.");
          setError({
            status: HTTP_STATUS_INTERNAL_ERROR,
            message: "User does not have access to any routes.",
          });
          clearUserData();
          return;
        }

        // Get the first route from the filtered routes
        const firstRoutePath = _.get(filteredRoutes, "[0][1].path", "/");
        // Set the initial route path in the user data to save in local storage
        data.user.initialRoute = firstRoutePath;
        data.user.groups = userGroups;
        saveUserData(data, firstRoutePath);
        setError(null);
      }
    } catch (err) {
      const errorStatus = _.get(
        err,
        "response.status",
        HTTP_STATUS_INTERNAL_ERROR
      );
      const errorMessage = _.get(err, "response.data.message", FAILED_MSG);
      console.error(
        "Error occurred while logging in",
        errorMessage,
        errorStatus,
        err
      );
      setError({ status: errorStatus, message: errorMessage });
      clearUserData();
    }
  }, []);

  // Logout function
  const logout = useCallback(async () => {
    if (token) {
      try {
        await query.post("/logout");
      } catch (err) {
        console.error("Error occurred while logging out from the server.", err);
      }
    }

    clearUserData();
  }, []);

  // Function to check token validity
  const checkTokenValidity = useCallback(async () => {
    try {
      const response = await query.get("/verify-token");
      return (
        response.status === HTTP_STATUS_OK ||
        response.status === HTTP_STATUS_NO_CONTENT
      );
    } catch (err) {
      // Check if error status is 401 (Unauthorized)
      if (err.response?.status === 401) {
        return false;
      }

      // Otherwise, log the error and return false
      console.error("Error occurred while checking token validity", err);
      return false;
    }
  }, [query, token]);

  // Effect to check token validity on hook initialization
  useEffect(() => {
    if (userData && token) {
      checkTokenValidity().then((isValid) => {
        if (!isValid) {
          // Clear user data if token is invalid
          clearUserData();
        } else {
          console.log("Token is valid. Setting authenticated to true.");
          setAuthenticated(true);

          // Redirect to the default URL only if the current route is the login page
          if (window.location.pathname === LOGIN_URL) {
            console.log("Navigating to initial route: ", userData.initialRoute);
            navigate(userData.initialRoute);
          }
        }
      });
    } else {
      console.log("No user data or token found. Clearing user data.");
      clearUserData();
    }
  }, [userData || token, checkTokenValidity]);

  // Listen for error changes and show snackbar
  useEffect(() => {
    if (error) {
      showSnackbar(error.message, "error");
      setError(null);
    }
  }, [error, showSnackbar]);

  return { userData, authenticated, token, query, error, login, logout };
};

const AuthenticationContext = createContext(null);

/**
 * Provides the login context to its children.
 * @param {React.PropsWithChildren} props - The component props.
 * @returns {JSX.Element} - The component children.
 */
const AuthenticationProvider = ({ children }) => {
  const login = useAuthenticationHook();
  return (
    <AuthenticationContext.Provider value={login}>
      {children}
    </AuthenticationContext.Provider>
  );
};

/**
 * Custom hook to use the login context.
 * @returns {{
 *   userData: { username: string, fullname: string, email: string, groups: [string|number] } | null,
 *   authenticated: boolean,
 *   token: string | null,
 *   query: AxiosInstance,
 *   error: { status: number, message: string } | null,
 *   login: ({ username: string, password: string, sendToURL: string }) => Promise<void>
 *   logout: () => Promise<void>
 * }} - The login state, error information, and the login function.
 */
const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error(
      "useAuthentication must be used within a AuthenticationProvider"
    );
  }
  return context;
};

export { useAuthentication, AuthenticationProvider, useAuthenticationHook };
export default useAuthentication;
