import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CustomBootstrapDialog(
    {
        thumbnail,
        installImageFilePath,
        fetchInstallImage,
    })
{
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <img
                src={`${thumbnail}`}
                alt="Hello"
                loading="lazy"
                onClick={(e) =>
                    {
                        e.preventDefault();
                        setOpen(true);
                    }
                }
            />
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                    Install Image
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers>
                    <img
                        src={`${thumbnail}`}
                        alt="Hello"
                        loading="lazy"
                        style={{maxWidth:"328px", maxHeight:"328px", width:"275px", height:"275px"}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={ (e) => {
                            e.preventDefault();
                            fetchInstallImage(true, installImageFilePath)
                        }}
                    >
                        Download Image
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
