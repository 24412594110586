import * as React from "react";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import StandardImageList from "@pages/InstPic/InstallImages/components/InstallImagesImageList";
import { Box } from "@mui/material";

function createColumns({ otherProps }) {
  return [
    {
      field: "idNumber",
      headerName: "ID",
      width: 150,
    },
    {
      field: "Files",
      headerName: "Install Images",
      width: 656,
      renderCell: (params) => {
        return <StandardImageList imagesArray={params.value} {...otherProps} />;
      },
    },
  ];
}

function createRowsArray(typeFolderInfoObject) {
  let rows = [];
  let rowObject = {
    id: "",
    idNumber: "",
    Files: [],
  };

  Object.keys(typeFolderInfoObject).map((keyName, keyIndex) => {
    const documentFolderItem = typeFolderInfoObject[keyName];
    rowObject = {
      ...rowObject,
      id: keyIndex,
      idNumber: keyName,
      Files: documentFolderItem.Files,
    };
    rows = [...rows, rowObject];
  });

  return rows;
}

export default function InstallImagesDataGridPremium({
  typeFolderInfoObject,
  fetchInstallImage,
  installImageLoading,
  installImageError,
  installImageResults,
  setInstallImageFilePath,
}) {
  const rowsInput = createRowsArray(typeFolderInfoObject);

  const columns = React.useMemo(() => {
    return createColumns({
      otherProps: {
        fetchInstallImage,
        installImageLoading,
        installImageError,
        installImageResults,
        setInstallImageFilePath,
      },
    });
  }, [
    fetchInstallImage,
    installImageLoading,
    installImageError,
    installImageResults,
    setInstallImageFilePath,
  ]);

  return (
    <Box
      style={{
        height: "calc(100vh - 300px)",
        width: "100%",
      }}
    >
      <DataGridPremium
        getRowHeight={() => "auto"}
        rows={rowsInput}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: "idNumber", sort: "desc" }],
          },
        }}
      />
    </Box>
  );
}
