import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CustomBootstrapDialog from './CustomBootstrapDialog';

export default function StandardImageList(
    {
        imagesArray,
        fetchInstallImage,
        installImageLoading,
        installImageError,
        installImageResults,
        setInstallImageFilePath
    }) {
    let imagesArray2 = {Files:[]};

    if(imagesArray && !imagesArray['Files']) {
        imagesArray2 = { ...imagesArray2, Files: [...imagesArray] };
    } else if(imagesArray && imagesArray['Files']) {
        imagesArray2 = imagesArray;
    }

    return (
        (imagesArray2 && imagesArray2.Files.length > 0) && (
            <ImageList sx={{ width: 656, height: 164 }} cols={4} rowHeight={164}>
                {imagesArray2.Files.map((item) => (
                    <ImageListItem key={item.filepath}>
                        <CustomBootstrapDialog
                            thumbnail={item.thumbnail}
                            installImageFilePath={item.filepath}
                            fetchInstallImage={fetchInstallImage}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        )
    );
}