import { Navigate } from "react-router-dom";
import { UserPlusIcon } from "@heroicons/react/24/outline";
// Report Card
import CallCreation from "../pages/Admin/CallCreation";
import AdminContext from "@contexts/AdminContext";
import InstallImages from "@pages/InstPic/InstallImages";

/**
 * Represents a navigation child route with a specific path, component to render, and an optional icon.
 * @typedef {Object} NavigationChild
 * @property {string} path - The path of the child route.
 * @property {JSX.Element} Element - The main component for the child route, rendered as a JSX element.
 * @property {JSX.Element} [Icon] - The icon for the child route, rendered as a JSX element (optional).
 */

/**
 * Represents the root configuration of a navigation route, including its path, main component, optional context provider, and optional icon.
 * @typedef {Object} NavigationRoot
 * @property {string} path - The path of the root route.
 * @property {React.ComponentType} [Context] - The context provider component for the root route (optional).
 * @property {JSX.Element} Element - The main component for the root route, rendered as a JSX element.
 * @property {JSX.Element} [Icon] - The icon for the root route, rendered as a JSX element (optional).
 */

/**
 * Describes a complete navigation route, including its group, root configuration, and any child routes.
 * @typedef {Object} NavigationRoute
 * @property {string|string[]} group - The group(s) associated with the route, used for grouping or permissions.
 * @property {NavigationRoot} root - The root configuration of the route.
 * @property {NavigationChild[]} [children] - The child routes of the current route (optional).
 */

/**
 * Defines the structure for the application's navigation routes, where each key is a route name mapping to its configuration.
 * @type {{ [key: string]: NavigationRoute }}
 */
export const navigationRoutes = {
  default: {
    path: "/",
    direct: "/login",
    logout: "/logout",
    Element: <Navigate to="/login" replace />,
    adminGroup: "App_VAIExplorer_Admin",
  },

  callCreation: {
    groups: ["App_VAIExplorer_Admin"],
    Context: AdminContext,
    path: "/call-creation",
    text: "Call Creation",
    Element: CallCreation,
    Icon: UserPlusIcon,
  },

  instPicLookUp: {
    groups: null,
    Context: AdminContext,
    path: "/instpic/install-images",
    text: "Install Picture Lookup",
    Element: InstallImages,
    Icon: UserPlusIcon,
  },
};

export default navigationRoutes;
