import React from "react";
import { CircularProgress } from "@mui/material";

const LoadingIndicator = (props) => {
  const { styles } = props;

  // Set the class names from the styles prop
  const classNames = styles || "flex justify-center items-center h-full w-full";

  return (
    <div className={classNames}>
      <CircularProgress />
    </div>
  );
};

export default LoadingIndicator;
