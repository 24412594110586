import useAuthentication from "@globalHooks/useAuthentication";
import {useState} from "react";

export default function useInstallImagesInfo() {
    const {query} = useAuthentication();

    const [installImagesInfoLoading, setInstallImagesInfoLoading] = useState(false);
    const [installImagesInfoError, setInstallImagesInfoError] = useState(null);
    const [installImagesInfoResults, setInstallImagesInfoResults] = useState(null);

    const [installImageLoading, setInstallImageLoading] = useState(false);
    const [installImageError, setInstallImageError] = useState(null);
    const [installImageResults, setInstallImageResults] = useState(null);

    const [ installImageFilePath, setInstallImageFilePath ] = useState("");

    // From the Selects
    const fetchInstallImagesInfo = async (
        {
            dev,
            customerNumber,
        }
    ) => {
        console.log("Fetching install images for customer number: ", customerNumber);

        let installImagesInfoData = {
            dev: dev,
            customer_number: "",
        }

        setInstallImagesInfoLoading(true);
        try {
            setInstallImagesInfoError(null);
            if (customerNumber.length >= 7) {
                installImagesInfoData = {
                    ...installImagesInfoData,
                    customer_number: customerNumber,
                };
            } else {
                throw new Error("The customer number is too short");
            }

            const data = await query.post(
                "/instpic/list-dir",
                installImagesInfoData
            );

            setInstallImagesInfoResults(data);
            console.log("Images successfully fetched");
        } catch (error) {
            setInstallImagesInfoError(error);
            console.log(error);
        } finally {
            setInstallImagesInfoLoading(false);
        }
    };

    const fetchInstallImage = async (
            dev,
            filepath
    ) => {
        let installImageData = {
            dev: dev,
            filepath: filepath,
        }

        setInstallImageLoading(true);
        try {
            setInstallImageError(null);
            const data = await query.post(
                "/instpic/get-image",
                installImageData, {responseType: "blob"}
            );
            setInstallImageResults(data);

            const fileURL = URL.createObjectURL(data.data);
            const extension = () => {
                const indexOfSlash = data.data.type.lastIndexOf("/");
                let afterSlash = data.data.type.substring(indexOfSlash + 1);
                if (afterSlash === 'xml') {
                    afterSlash = 'pdf';
                }

                return afterSlash;
            }

            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "Install-Image-Or-Pdf." + extension();
            alink.click();
        } catch (error) {
            setInstallImageError(error);
            console.log("There was an error in fetching install image data.");
            console.log(error);
        } finally {
            setInstallImageLoading(false);
        }
    };

    return {
        installImagesInfoLoading,
        installImagesInfoError,
        installImagesInfoResults,
        fetchInstallImagesInfo,
        setInstallImagesInfoError,
        setInstallImagesInfoLoading,
        fetchInstallImage,
        installImageLoading,
        installImageError,
        installImageResults,
        setInstallImageFilePath,
    };
}