import React, { createContext, useContext, useEffect, useState } from "react";

const MuiSidebarContext = createContext();

const useMuiSidebar = () => useContext(MuiSidebarContext);

export const MuiSidebarProvider = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [showAppbar, setShowAppbar] = useState(false);
  const [appbarComponent, setAppbarComponent] = useState(null);

  const toggleAppbar = (show) => setShowAppbar(show);

  const toggleSidebar = (show = null) => setShowSidebar(show ?? !showSidebar);

  // Auto toggle appbar when screen size is small
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 960) {
        setShowAppbar(true);
      } else {
        setShowAppbar(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <MuiSidebarContext.Provider
      value={{
        showAppbar,
        toggleAppbar,
        setAppbarComponent,
        appbarComponent,
        toggleSidebar,
        showSidebar,
      }}
    >
      {children}
    </MuiSidebarContext.Provider>
  );
};

export default useMuiSidebar;
